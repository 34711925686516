import React, { useEffect, useState } from 'react';
import {
    Image, Box, Text, Flex, SimpleGrid, useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react';
import { StyleSheet, css } from 'aphrodite';
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from 'actions/actions';

function NewsCardsFr() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image
    const isMobile = useSelector(state => state.windowSize.isMobile);
    const dispatch = useDispatch();
    
    useEffect(() => {
        // Dispatch the fetchData action when the component mounts
        dispatch(fetchData());
    }, [dispatch]);

    const openModalWithImage = (imageSrc) => {
        setSelectedImage(imageSrc); // Set the image based on the clicked card
        onOpen();
    };

    return (
        <>
            <SimpleGrid columns={isMobile ? 1 : 3} spacing={10}
                className={css(styles.gridStyle)}
                mt={isMobile ? 3 : 5}
            >
                <Box className={css(styles.card)}>
                    <Text className={css(styles.heading)}>
                        Atelier TRAVERSAL en association avec le Forum mondial de l'IEEE sur l'Internet des objets
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        le 10 novembre 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        Westin - Ottawa
                    </Text>
                </Box>

                <Box className={css(styles.card)} onClick={() => openModalWithImage('NewsHack_fr.jpg')}>
                    <Text className={css(styles.heading)}>
                        Atelier ANNUEL ET HACKATHON TAVERSAL
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        le 20 juin 2024 - le 22 juin 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>

                <Box className={css(styles.card)}>
                    <Text className={css(styles.heading)}>
                        Événement NEM-Ontario co-organisé avec la série TRAVERSAL sur le transfert de technologie et l'entrepreneuriat (TTE)
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        le 25 mars 2024 - le 30 mars 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>
                
                <Box className={css(styles.card)} onClick={() => openModalWithImage('NewsSurvey_fr.jpg')}>
                    <Text className={css(styles.heading)}>
                        Atelier d'enquête et de tutorat sur les parcours 
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        le 20 février 2024 - le 1er mars 2024
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>

                {/* First card */}
                <Box className={css(styles.card)} onClick={() => openModalWithImage('NewsEDI_fr.jpg')}>
                    <Text className={css(styles.heading)}>
                        Atelier CREATE TRAVERSAL EDI
                    </Text>
                    <Text className={css(styles.subtitle)}>
                        le 18 décembre 2023 - le 22 décembre 2023
                    </Text>
                    <Flex justifyContent='center'>
                        <hr style={{ width: '50%', borderWidth: '2px' }} />
                    </Flex>
                    <Text className={css(styles.subtitle)}>
                        535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
                    </Text>
                </Box>

            </SimpleGrid>

            {/* Modal */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered size='3xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>À propos de l'événement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedImage && <Image src={selectedImage} alt='Selected image' width='100%' />}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

const styles = StyleSheet.create({
    tabParent: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#DC242C'
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '18',
        textAlign: 'center',
        marginTop: 5
    },
    subtitle: {
        fontSize: '20',
        textAlign: 'center',
        marginTop: 5,
        fontWeight: 'bold'
    },
    card: {
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: 'gray',
        justifyContent: 'center',
        textAlign: 'center',
        ':hover': {
          cursor: 'pointer'  
        },
        position: 'relative'
    },
    gridStyle: {
        justifyContent: 'center',
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10
    }
})

export default NewsCardsFr